import React from "react";
import ExperienceCard from "./ExperienceCard";

const Education = () => {
  return (
    <div className="text-[#e2e8f0]" id="education">
      <div className="max-w-[800px] w-full mx-auto flex flex-col p-5 mt-10">
        <h2 className="lg:hidden text-xl">EDUCATION</h2>
        <ExperienceCard
          date="2021 - 2023"
          title="MSc Computer Science • University of East London"
          description="Grade: First Class Honours"
          languages={
            "Artificial Intelligence and Machine Vision, Machine Learning and Big Data, Big Data Analytics, Intelligent Systems"
          }
          link={"https://uel.ac.uk/postgraduate/courses/msc-computer-science"}
        />
        <ExperienceCard
          date="2018 - 2021"
          title="BSc Computer Science • University of East London"
          description="Grade: First Class Honours"
          descriptionLine2="Honors & Awards:
          Outstanding Achievement Prize for the BSc Dissertation"
          languages={
            "Advanced Programming, Data Structures and Algorithms, Maths for Computing, Web Technologies, Information Systems Modelling and Design, Data Communications and Networks"
          }
          link={
            "https://uel.ac.uk/undergraduate/courses/bsc-hons-computer-science"
          }
        />
      </div>
    </div>
  );
};

export default Education;
