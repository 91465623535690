import React from "react";
import ExperienceCard from "./ExperienceCard";

const Experience = () => {
  return (
    <div className="text-[#e2e8f0]" id="experience">
      <div className="max-w-[800px] w-full mx-auto flex flex-col p-5 mt-10">
        <h2 className="lg:hidden text-xl">EXPERIENCE</h2>
        <ExperienceCard
          date="SEPT 22 - PRESENT"
          title="Software Developer • Safehouse"
          description=" Developed a web application using React.js and Tailwind, utilizing modern front-end development techniques to create responsive and user-friendly interface.
          Worked extensively with APIs to retrieve and display dynamic content in web - mobile applications, improving user engagement and experience.
          Utilized React Native to build a cross-platform mobile application for iOS and Android, implementing key features and functionality while ensuring seamless performance and user experience on both platforms."
          languages="HTML, CSS, JavaScript, React.js, React Native, Next.js, Java, Git, GitHub, Firebase, Tailwind"
          link={"https://safehouse-viper.technology"}
        />
        <ExperienceCard
          date="JAN - JUN 2020"
          title="Frontend Developer • Oreo Studio"
          description="I designed and developed scalable, responsive, multi-platform, and SEO-friendly websites for multiple clients, employing HTML5, CSS3 (utilizing BEM methodology), JavaScript ES6+, and React.js, following industry best practices.
          I effectively communicated business requirements with stakeholders and successfully delivered high-quality solutions.
          I seamlessly connected to backend APIs by implementing CRUD HTTP requests."
          languages="HTML, CSS, JavaScript, React"
          link={"https://www.teamoreo.co.uk"}
        />
      </div>
    </div>
  );
};

export default Experience;
