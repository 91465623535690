import React from "react";
import { BsArrowUpRight } from "react-icons/bs";
import Languages from "./Languages";

const ExperienceCard = ({
  date,
  title,
  description,
  descriptionLine2,
  languages,
  link,
}) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <div className="grid lg:grid-cols-4 items-baseline mt-10 p-3 hover:bg-[#94a3b8]/5 rounded-lg hover:shadow-2xl hover:cursor-pointer hover:text-[#4DC9CA]">
        <div className="lg:col-span-1">
          <h3 className="font-light text-sm text-[#94a3b8] uppercase">
            {date}
          </h3>
        </div>
        <div className="lg:col-span-3">
          <h1 className="flex items-baseline">
            {title} <BsArrowUpRight className="ml-3" />
          </h1>
          <p className="mt-5 text-[#94a3b8] text-sm">{description}</p>
          {descriptionLine2 && (
            <p className="mt-5 text-[#94a3b8] text-sm">{descriptionLine2}</p>
          )}
          <Languages languages={languages} />
        </div>
      </div>
    </a>
  );
};

export default ExperienceCard;
