import React, { useState } from "react";
import { BsArrowUpRight } from "react-icons/bs";
import Languages from "./Languages";

function ProjectsCard({ image, title, description, languages, link }) {
  const cursorStyle = link ? "pointer" : "default";
  const titleStyle = link ? "" : "text-gray-300"; // Add your desired CSS class for grey font color

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div
        className="grid lg:grid-cols-4 gap-4 mt-10 p-3 hover:bg-[#94a3b8]/5 hover:shadow-2xl rounded-lg"
        style={{ cursor: cursorStyle, color: link ? "#4DC9CA" : "#000" }}
        onClick={handleClick}
      >
        <div className="lg:col-span-1 hover:cursor-pointer">
          <img src={image} className="rounded-lg" alt="/" />
        </div>

        <div className="lg:col-span-3">
          <a href={link} target="_blank" rel="noopener noreferrer">
            <h1 className={`flex items-baseline ${titleStyle}`}>
              {title} {link && <BsArrowUpRight className="ml-3" />}
            </h1>
            <p className="mt-5 text-[#94a3b8] text-sm">{description}</p>
            <Languages languages={languages} />
          </a>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal hidden md:block">
          <div className="modal-content">
            <span className="close hover:cursor-pointer" onClick={closeModal}>
              &times;
            </span>
            <img src={image} alt="Enlarged" />
          </div>
        </div>
      )}
    </div>
  );
}

export default ProjectsCard;
