import React from "react";

const Languages = ({ languages }) => {
  return (
    <div className="flex justify-start flex-wrap">
      {languages &&
        languages.split(",").map((language, index) => (
          <p
            key={index}
            className="mt-2 text-xs px-2 py-2 mr-2 text-[#4DC9CA] bg-[#4DC9CA]/20 rounded-lg"
          >
            {language.trim()}
          </p>
        ))}
    </div>
  );
};

export default Languages;
