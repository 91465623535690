import React from "react";
import FeaturedProjectsCard from "./FeaturedProjectsCard";
import safehouseviper from "../assets/safhouseviper.jpg";
import netflix_clone from "../assets/netflix-clone.jpg";
import istudymena from "../assets/istudymena.jpg";
import bookwise from "../assets/bookwise.jpg";
import dashboard from "../assets/dashboard.jpg";
import pythonbank from "../assets/pythonbank.jpg";
import pythonhandgesture from "../assets/pythonhandgesture.jpg";
import ProjectsCard from "./ProjectsCard";
import protcopy from "../assets/protcopy.jpg";
import applern from "../assets/applern.jpg";

const Projects = () => {
  return (
    <div className="text-[#e2e8f0]" id="projects">
      <div className="max-w-[800px] w-full mx-auto flex flex-col p-5 mt-10">
        <h2 className="lg:hidden text-xl">PROJECTS</h2>
        <div className="flex flex-col items-center justify-center">
          <FeaturedProjectsCard
            image={safehouseviper}
            title="Safehouse Viper"
            description="Developed utilizing React Native for iOS and Android platforms, SafeHouse Viper is an advanced application designed to provide users with crucial insights and enhanced data accessibility. This innovative solution integrates seamlessly with IoT sensors, empowering users to access valuable information, real-time updates, and comprehensive visibility. With SafeHouse Viper, users can conveniently centralize and monitor all their installed IoT devices, while enjoying round-the-clock online access from anywhere across the globe. This dynamic application is readily available on both iOS and Android platforms."
            languages="React Native, API's"
            link={"https://apps.apple.com/gb/app/safehouse-viper/id6449365656"}
          />
          <ProjectsCard
            image={bookwise}
            title="Library"
            description="Developed an interactive online book library using React, React-Router, and Tailwind, integrated with a local JSON file to manage book properties. Implemented Skeletal Loading States for enhanced user experience. Offered sorting options based on price and rating. Designed individual book pages with recommended books feature, along with seamless cart functionality."
            languages="React, Tailwind, API's"
            // link={"https://bookwiselibrary.netlify.app"}
            link={"https://velvety-bunny-efdacc.netlify.app"}
          />
          <ProjectsCard
            image={pythonbank}
            title="Python Bank Management System"
            description="Created a Python banking application with diverse functionalities using object-oriented programming, including the incorporation of multiple banks, branches, accounts, and transaction management."
            languages="Python"
          />
          <ProjectsCard
            image={pythonhandgesture}
            title="Hand Gesture Recognition (Outstanding Achievement Prize for the BSc Dissertation)"
            description="Developed an application focused on enhancing the accuracy of hand gesture recognition. This involves studying and analyzing diverse datasets, exploring techniques and tools for feature extraction in data classification, and leveraging OpenCV and Python to construct and integrate real-time pattern recognition algorithms for hand gestures."
            languages="Python, OpenCV, NumPy, Keras, TensorFlow, imutils, matplotlib, mlxtend, scikit, tqdm, Pandas"
          />
          <ProjectsCard
            image={netflix_clone}
            title="Netflix Clone"
            description="Discover movie details and save your favorite films. Built using React, Tailwind, Firebase, and seamlessly integrated with the Movie Database API, this application provides a seamless browsing experience. Explore comprehensive information about movies and easily store your favorites."
            languages="React, Tailwind, Firebase, TMDB Api"
            link={"https://moviewebclone.netlify.app"}
          />
          <ProjectsCard
            image={applern}
            title="Apple Clone Web App"
            description="Developed a stylish and contemporary Apple website replica utilizing React and React Router incorporating data integration and Tailwind for sophisticated design aesthetics."
            languages="React, React Router, GitHub, TailwindCSS"
            link={"https://darling-pika-903e63.netlify.app"}
          />
          <ProjectsCard
            image={protcopy}
            title="Modern Protfolio Clone"
            description="Created a modern portfolio clone design using React. The project is fully responsive, scalable, and adheres to best programming practices delivering high-quality and scalable web solutions."
            languages="React"
          />
          <ProjectsCard
            image={dashboard}
            title="Dashboard"
            description="Created a user-friendly React.js-based dashboard with real-time data visualization on hover interactions, leveraging the Recharts library. Incorporated modern design principles to enhance user experience."
            languages="React, ReCharts"
          />
          <ProjectsCard
            image={istudymena}
            title="iStudy MENA"
            description="iStudy MENA is an agency dedicated to helping students apply to universities, mainly from the Middle East. It aims to provide comprehensive support and guidance to students throughout their university application process. As part of this project, I've worked on building the website and creating a seamless user experience using React and Tailwind CSS."
            languages="React, Tailwind"
            link={"http://istudymena.com/"}
          />

          <button className="mt-10 px-2 py-2 text-[#94a3b8] hover:text-[#4DC9CA] hover:bg-[#4DC9CA]/20 rounded-lg border border-[#94a3b8] hover:border-[#4DC9CA]">
            <a
              href="https://github.com/yacoub33"
              target="_blank"
              rel="noopener noreferrer"
            >
              View More
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Projects;
