import React, { useState } from "react";
import { Link } from "react-scroll";
import { FaGithub, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import ProfilePicture from "../assets/pp.JPG";

const Hero = () => {
  return (
    <div>
      {/* <div className="text-[#e2e8f0] lg:block hidden">
        <div className="max-w-[800px] w-full lg:h-screen mx-auto flex flex-col p-5 lg:mt-10 mt-5">
          <img
            src={ProfilePicture}
            className="hidden lg:flex md:flex w-40 rounded-full m-5 shadow border lg:mx-auto border-[#e2e8f0]"
            style={{ borderWidth: "2px" }}
            alt="/"
          />
          <h1 className="lg:text-5xl text-4xl font-semibold">Yacoub Haddad</h1>
          <h2 className="lg:text-1xl text-l font-light mt-5 text-[#94a3b8]">
            Analyst | Private Markets Advisory at{" "}
            <span className="text-[#e2e8f0]">
              <a
                href="https://lionpointgroup.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Lionpoint Group
              </a>
            </span>
          </h2>
          <p className="mt-5 max-w-[400px] text-[#94a3b8]">
            Enthusiastic software engineer with expertise in developing web and
            mobile applications as well as artificial intelligence.
          </p>

          <nav>
            <ul className="hidden lg:flex flex-col mt-[60px]">
              <li className="mb-5 text-sm text-[#94a3b8] hover:cursor-pointer hover:text-[#e2e8f0]">
                <Link
                  smooth
                  spy
                  to="about"
                  offset={0}
                  activeClass={`text-white`}
                  activeStyle={{
                    borderBottom: "1px solid white",
                    paddingBottom: "5px",
                  }}
                >
                  ABOUT
                </Link>
              </li>
              <li className="mb-5 text-sm text-[#94a3b8] hover:cursor-pointer hover:text-[#e2e8f0]">
                <Link
                  smooth
                  spy
                  to="experience"
                  offset={0}
                  activeClass={`text-white`}
                  activeStyle={{
                    borderBottom: "1px solid white",
                    paddingBottom: "5px",
                  }}
                >
                  EXPERIENCE
                </Link>
              </li>
              <li className="mb-5 text-sm text-[#94a3b8] hover:cursor-pointer hover:text-[#e2e8f0]">
                <Link
                  smooth
                  spy
                  to="projects"
                  offset={0}
                  activeClass={`text-white`}
                  activeStyle={{
                    borderBottom: "1px solid white",
                    paddingBottom: "5px",
                  }}
                >
                  PROJECTS
                </Link>
              </li>
              <li className="mb-5 text-sm text-[#94a3b8] hover:cursor-pointer hover:text-[#e2e8f0]">
                <Link
                  smooth
                  spy
                  to="education"
                  offset={0}
                  activeClass={`text-white`}
                  activeStyle={{
                    borderBottom: "1px solid white",
                    paddingBottom: "5px",
                  }}
                >
                  EDUCATION
                </Link>
              </li>
            </ul>
          </nav>

          <div className="flex flex-row mt-10">
            <a
              href="https://github.com/yacoub33"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub
                size={26}
                className="mr-4 text-[#94a3b8] hover:text-[#e2e8f0] hover:cursor-pointer"
              />
            </a>
            <a
              href="https://www.linkedin.com/in/yacoubhaddad/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin
                size={26}
                className="mr-4 text-[#94a3b8] hover:text-[#e2e8f0] hover:cursor-pointer"
              />
            </a>
            <a
              href="https://www.instagram.com/yacoubhaddad33/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram
                size={26}
                className="mr-4 text-[#94a3b8] hover:text-[#e2e8f0] hover:cursor-pointer"
              />
            </a>
          </div>
        </div>
      </div> */}
      <div className="text-[#e2e8f0] block">
        <div className="max-w-[800px] w-full h-screen mx-auto justify-stretch text-center flex flex-col p-5 pt-28">
          <img
            src={ProfilePicture}
            className=" lg:flex w-40 rounded-full m-5 shadow border mx-auto border-[#e2e8f0]"
            style={{ borderWidth: "2px" }}
            alt="/"
          />
          <h1 className="lg:text-5xl text-4xl font-semibold">Yacoub Haddad</h1>
          <h2 className="lg:text-1xl text-l font-light mt-5 text-[#94a3b8]">
            Analyst | Private Markets Advisory at <br></br>{" "}
            <span className="text-[#e2e8f0]">
              <a
                href="https://lionpointgroup.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Lionpoint Group
              </a>
            </span>
          </h2>

          <div className="flex flex-row mt-28 justify-center">
            <a
              href="https://github.com/yacoub33"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub
                size={26}
                className="mr-4 text-[#94a3b8] hover:text-[#e2e8f0] hover:cursor-pointer"
              />
            </a>
            <a
              href="https://www.linkedin.com/in/yacoubhaddad/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin
                size={26}
                className="mr-4 text-[#94a3b8] hover:text-[#e2e8f0] hover:cursor-pointer"
              />
            </a>
            <a
              href="https://www.instagram.com/yacoubhaddad33/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram
                size={26}
                className="mr-4 text-[#94a3b8] hover:text-[#e2e8f0] hover:cursor-pointer"
              />
            </a>
          </div>
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2">
            <p className="text-center text-[#94a3b8]/50 mb-5">
              Yacoub Haddad © 2023
            </p>
          </div>

          {/* <div className="flex justify-center animate-bounce absolute inset-x-0 bottom-[87%]">
            <FiChevronDown size={24} />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
