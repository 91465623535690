import About from "./components/About";
import Education from "./components/Education";
import Experience from "./components/Experience";
import Hero from "./components/Hero";
import Projects from "./components/Projects";
import CustomCursor from "./components/CustomCursor";

function App() {
  return (
    <div>
      <CustomCursor />
      <div className="max-w-[1240px] mx-auto ">
        <div className="">
          <div className="">
            <Hero />
          </div>
        </div>
        <div className="hidden">
          <About />
          <Experience />
          <Projects />
          <Education />
        </div>
      </div>
      {/* <p className="text-center text-[#94a3b8]/50 mb-5 hidden md:block">
        Yacoub Haddad © 2023
      </p> */}
    </div>
  );
}

export default App;
