import React from "react";
import { BsArrowUpRight } from "react-icons/bs";
import Languages from "./Languages";

const FeaturedProjectsCard = ({
  image,
  title,
  description,
  languages,
  link,
}) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <div className="grid lg:grid-cols-4 mt-10 p-3 hover:bg-[#94a3b8]/5 hover:shadow-2xl rounded-lg hover:cursor-pointer hover:text-[#4DC9CA]">
        <div className="lg:col-span-4 mb-5">
          <img src={image} className="rounded-lg" alt="/" />
        </div>
        <div className="lg:col-span-4">
          <h1 className="flex items-baseline">
            {title} <BsArrowUpRight className="ml-3" />
          </h1>
          <p className="mt-5 text-[#94a3b8] text-sm">{description}</p>
          <Languages languages={languages} />
        </div>
      </div>
    </a>
  );
};

export default FeaturedProjectsCard;
