import React from "react";

const About = () => {
  return (
    <div className="text-[#e2e8f0]" id="about">
      <div className="max-w-[800px] w-full mx-auto flex flex-col p-5 mt-10">
        <h2 className="lg:hidden text-xl">ABOUT</h2>
        <p className="mt-5 text-[#94a3b8]">
          Hello My name is Yacoub and I find great fulfillment in developing
          digital solutions that thrive and excel in the expansive landscape of
          the internet. Currently, I am a Master of Science candidate in
          Computer Science at the University of East London. With experience
          gained from working in the tech industry, particularly with
          JavaScript, I have demonstrated my ability to work both independently
          and as part of a team, successfully delivering projects. My academic
          pursuits have equipped me with a strong foundation in Data Structures
          and Algorithms, enabling me to approach problem-solving with a
          programmer's mindset..
        </p>
      </div>
    </div>
  );
};

export default About;
